<template>
  <div>
    <s-notification-list />
    <div class="outer">
      <NavigationSide
        v-if="!isLoginPage"
        class="navigation"
      />
      <div class="main">
        <nuxt/>
      </div>
    </div>
    <sticky-timer v-if="!isLoginPage"></sticky-timer>
    <slideout-panel></slideout-panel>
  </div>
</template>

<script>
  import NavigationSide from '~/components/NavigationSide'
  import stickyTimer from '../components/dashboard/StickyTimer'
  import profileQuery from "~/graphql/queries/profile.graphql";
  import organisationsQuery from "~/graphql/queries/organisations.graphql";

  export default {
    components: {
      stickyTimer,
      NavigationSide
    },
    apollo: {
      profile: {
        query: profileQuery,
      },
      organisations: {
        query: organisationsQuery,
      }
    },
    computed: {
      userProfile () {
        if (this.profile) {
          return this.profile.userProfile
        }
        return {}
      },
      isLoginPage () {
        return this.$route.path === '/login'
      }
    },
    mounted() {
      window.addEventListener("offline", () => {
        this.$notify('You are offline!', 'is-danger', true);
      });

      window.addEventListener("online", () => {
        this.$notify('You are back online! 🎉', 'is-success', true);
      });

      if (window.navigator.connection?.effectiveType.endsWith("2g")) {
        this.$notify('Slow internet connection', 'is-warning', true);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .outer {
    display: grid;
    grid-template-columns: 240px calc(100vw - 240px);

    @media print {
      grid-template-columns: 1fr;
    }
  }

  .navigation {
    overflow: visible;
  }

  .main {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 110px !important;
  }
</style>
