<template>
  <div class="main">
    <a target="_blank" href="https://sybill.io/ ">
      <logo class="logo" />
    </a>
    <nuxt />
  </div>
</template>

<script>
import Logo from '~/components/Logo.vue'

export default {
  components: { Logo },
  mounted() {
    window.addEventListener('offline', () => {
      this.$notify('You are offline!', 'is-danger', true)
    })

    window.addEventListener('online', () => {
      this.$notify('You are back online! 🎉', 'is-success', true)
    })

    if (window.navigator.connection?.effectiveType.endsWith('2g')) {
      this.$notify('Slow internet connection', 'is-warning', true)
    }
  },
}
</script>

<style lang="scss" scoped>
.main {
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  background-image: url('../assets/textless-logo.svg');
  background-repeat: no-repeat;
  background-position: bottom 48px right 64px;
}

.logo {
  margin: 24px 0 0 64px;
  cursor: pointer;
}
</style>
